function formatCreateLoginsData (csvFileAsArrays) {
  const fileRows = [...csvFileAsArrays]
  const listToSend = []
  fileRows.reduce((acc, row) => {
    const dataObj = {}
    fileRows[0].forEach((key, index) => {
      let value = row[index]
      if (value === "TRUE") {
        value = true
      } else if (value === "FALSE") {
        value = false
      }
      dataObj[key] = value
    })
    listToSend.push(dataObj)
    return listToSend
  })
  const addUsersBulkRequest = {
    addUserRequestList: listToSend
  }
  return addUsersBulkRequest
}
module.exports = formatCreateLoginsData
