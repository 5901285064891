import { TrueorFalse } from '../dataValidations'
import { TypeOfPatientsAddedHUB, HubAccessTypes } from '../utils'
export const HUBCreateLoginsValidations = (rows) => {
  // Validate headers
  const rowError = validateCsvHeadings(rows[0])
  if (rowError) {
    return `${rowError}`
  }
  // validate entries
  const dataRows = rows.slice(1, rows.length) // ignore header at 0 and get rest of the rows
  for (let i = 0; i < dataRows.length; i++) {
    const rowError = validateCsvRow(dataRows[i])
    if (rowError) {
      return `${rowError} on row ${i + 2}`
    }
  }
}
function validateCsvHeadings (row) {
  if (!row[0] || !/^hierarchyId$/.test(row[0])){
    return "Column 1 heading should be hierarchyId"
  } else if (!row[1] || !/^username$/.test(row[1])){
    return "Column 2 heading should be username"
  } else if (!row[2] || !/^password$/.test(row[2])){
    return "Column 3 heading should be password"
  } else if (!row[3] || !/^primaryPhoneNumber$/.test(row[3])){
    return "Column 4 heading should be primaryPhoneNumber"
  } else if (!row[4] || !/^secondaryPhoneNumber$/.test(row[4])){
    return "Column 5 heading should be secondaryPhoneNumber"
  } else if (!row[5] || !/^emailAddress$/.test(row[5])){
    return "Column 6 heading should be emailAddress"
  } else if (!row[6] || !/^viewOnly$/.test(row[6])){
    return "Column 7 heading should be viewOnly"
  } else if (!row[7] || !/^canDeletePatients$/.test(row[7])){
    return "Column 8 heading should be canDeletePatients"
  } else if (!row[8] || !/^accessType$/.test(row[8])){
    return "Column 9 heading should be accessType"
  }
}
function validateCsvRow (row) {
  if (!row[0] || !/^[1-9][0-9]*$/.test(row[0])){
    return "Column 1 should have a valid hierarchyId"
  } else if (!row[1] || !/^([a-zA-Z0-9]*-* *_*[a-zA-Z0-9]*)+$/.test(row[1])){
    return "Column 2 should have a valid username"
  } else if (!/^[0-9]*$/.test(row[3])){
    return "Column 4 should have a valid primaryPhoneNumber"
  } else if (!/(^[0-9]+$|^$)/.test(row[4])){
    return "Column 5 should have a valid secondaryPhoneNumber"
  } else if (!row[6] || !TrueorFalse(row[6])){
    return "Column 7 should have a valid viewOnly"
  } else if (!row[7] || !TrueorFalse(row[7])){
    return "Column 8 should have a valid canDeletePatients"
  } else if (!row[8] || !HubAccessTypes(row[8])){
    return "Column 9 should have a valid accessType"
  }
}
