<template>
<v-app>
    <HUBCreateLoginsForm/>
</v-app>
</template>

<script>
import HUBCreateLoginsForm from '../components/HUBCreateLoginsForm'
import router from './../router/index'
import { mapState } from 'vuex'
import { routeNames } from '../../utils/dataValidations'
export default {
  name: 'Home',
  components: {
    HUBCreateLoginsForm
  },
  computed: {
    ...mapState([
      'isAuth'
    ])
  },
  mounted: function () {
    if (!this.isAuth) {
      router.push(routeNames.Home)
    }
  }
}
</script>
