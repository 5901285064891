<template>
  <LoginForm :Heading = "getHeading()" @submit="submit"/>
</template>

<script>
import LoginForm from './Form.vue'
import { mapState, mapActions } from 'vuex'
import { initializePlatformMatomo, DataForMatomoTracking, adminLogData } from '../../utils/matomoTracking'
import { apiResponseType, apiResponseValue } from '../../utils/dataValidations'
import { ApiClient } from '../store/api'
import { HUBCreateLoginsValidations } from './../../utils/HUBValidations/HUBCreateLoginsValidations'
const FormatCreateLoginsData = require("./../../utils/HUBValidations/FormatCreateLoginsData")
const csv = require("papaparse")

export default {
  components: { LoginForm },
  computed: {
    ...mapState([
      'isProd',
      'platform'
    ])
  },
  mounted: function () {
    initializePlatformMatomo()
  },
  beforeUpdate: function () {
    if (this.platform !== this.$route.query.platform) {
      this.clearMessage()
    }
  },
  methods: {
    ...mapActions([
      'loading',
      'setErrorMessage',
      'clearMessage',
      'success'
    ]),
    getHeading: function (){
      return this.$route.query.platform + ' Create Logins'
    },
    async submit (file, filename, username, password) {
      this.loading(true)
      this.setErrorMessage({ isError: false, errMsg: '' })
      this.success({ isOk: false, succMsg: '' })
      let data = null
      const parsedCSV = csv.parse(file, {
        complete: async function (results){
          const validationError = HUBCreateLoginsValidations(results.data)
          if (validationError){
            this.setErrorMessage({ isError: true, errMsg: validationError })
            this.loading(false)
          } else {
            data = FormatCreateLoginsData(results.data)
            let jsonUploadResponse
            try {
              jsonUploadResponse = await ApiClient.post(
                username,
                password,
                JSON.stringify(data),
                '/v1/admin/users', 'registryENV'
              )
            } catch (error) {
              this.setErrorMessage({ isError: true, errMsg: apiResponseType.JSONUploadFailed })
            }
            this.loading(false)
            if (jsonUploadResponse){
              if (jsonUploadResponse.data.success === true && jsonUploadResponse.data.data.failedLogins.length === 0){
                this.setErrorMessage({ isError: false, errMsg: '' })
                this.success({ isOk: true, succMsg: 'Logins are created.' })
              } else if (jsonUploadResponse.data.error != null){
                this.setErrorMessage({ isError: true, errMsg: jsonUploadResponse.data.error })
              } else if (jsonUploadResponse.data.data.failedLogins.length > 0) {
                var arrayobj = jsonUploadResponse.data.data.failedLogins

                var outputlist = arrayobj.map(function (item) {
                  return "['Username: ' + " + item.username + " : " + 'Phonenumber: ' + item.phoneNumber + "]" + "\n"
                })

                this.setErrorMessage({ isError: true, errMsg: outputlist + ' following logins were not created , please verify the entries and try again.' })
              } else {
                this.setErrorMessage({ isError: true, errMsg: 'Something went wrong' })
              }
              adminLogData(this.getHeading(), 'StatusCode: ' + jsonUploadResponse.status, 'Filename: ' + filename)

              DataForMatomoTracking(this.getHeading(), apiResponseType.SUCCESS, jsonUploadResponse.data.data.loginsCreated)
              DataForMatomoTracking(this.getHeading(), apiResponseType.FAILED, jsonUploadResponse.data.data.failedLogins)
            }
          }
        }.bind(this)
      })
    }
  }
}
</script>
